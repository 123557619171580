.fadeMe {
  position: fixed;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.spinner {
  margin: auto;
  margin-top: 200px;
  z-index: 999;
}

.chatroom {
  width: 260px;
  height: 420px;
  border: 2px solid #c99;
  /* margin: auto; */
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 10px;
}

.messageList {
  height: 300px;
  overflow-y: scroll;
}

@media only screen and (max-device-width: 600px) {
  .song_table {
    font-size: 20px;
  }
}
